.maplibregl-style-list
{
  display: none;
}

.mapboxgl-ctrl-group button, .maplibregl-ctrl-group button
{
  width: 38px;
  height: 38px;
}

.maplibregl-ctrl-group .maplibregl-style-list button
{
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  font-size: 14px;
  padding: 8px 8px 6px;
  text-align: right;
  width: 100%;
  height: auto;
}

.maplibregl-style-list button.active
{
  font-weight: bold;
}

.maplibregl-style-list button:hover
{
  background-color: rgba(0, 0, 0, 0.05);
}

.maplibregl-style-list button + button
{
  border-top: 1px solid #ddd;
}

.maplibregl-style-switcher
{ 
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20version%3D%221.1%22%20id%3D%22Capa_1%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2253.121px%22%20height%3D%2255px%22%20viewBox%3D%220%200%2053.121%2053.121%22%20style%3D%22enable-background%3Anew%200%200%2024.849%2024.849%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath%20d%3D%22m45.374%206.64%20-0.354%200.066L33.201%2011.288%2019.92%206.64%207.437%2010.846c-0.465%200.155%20-0.797%200.553%20-0.797%201.062V45.374c0%200.62%200.487%201.107%201.107%201.107l0.354%20-0.066L19.92%2041.833l13.28%204.648%2012.483%20-4.205c0.465%20-0.155%200.797%20-0.553%200.797%20-1.062V7.747c0%20-0.62%20-0.487%20-1.107%20-1.107%20-1.107M33.201%2042.054l-13.28%20-4.67V11.067l13.28%204.67z%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3Cg%2F%3E%3Cg%2F%3E%3Cg%2F%3E%3Cg%2F%3E%3Cg%2F%3E%3Cg%2F%3E%3Cg%2F%3E%3Cg%2F%3E%3Cg%2F%3E%3Cg%2F%3E%3Cg%2F%3E%3Cg%2F%3E%3Cg%2F%3E%3Cg%2F%3E%3Cg%2F%3E%3C%2Fsvg%3E);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
}
